<template>
	<div class="container">
		<div class="title">
			<span class="inner_title">停车场缴费记录</span>
		</div>
		<div class="top-box">
			<div class="top-one">
				<el-tag class="labelText" type="info">车牌号：</el-tag>
				<el-input class="rantHouseName" v-model="car_no" placeholder="请输入"></el-input>
			</div>
			<div class="top-one">
				<el-tag class="labelText" type="info">起始时间：</el-tag>
				<el-date-picker v-model="start_day" type="date" placeholder="选择日期时间" value-format="yyyy-MM-dd"></el-date-picker>
			</div>
			<div class="top-one">
				<el-tag class="labelText" type="info">结束时间：</el-tag>
				<el-date-picker v-model="end_day" type="date" placeholder="选择日期时间" value-format="yyyy-MM-dd"></el-date-picker>
			</div>
			<div class="top-one">
				<el-button type="primary" @click="searchClick()">搜索</el-button>
				<el-button type="warning" @click="cleaClick()">清空</el-button>
			</div>
		</div>
		<div>
			<el-table class="table" v-loading="loading" :element-loading-text="loadingText" element-loading-spinner="el-icon-loading"
			 :element-loading-background="loadingColor" :data="cashList" :header-cell-style="TableTitleBackColor" :row-style="tableCss"
			 :highlight-current-row="true" :cell-style="cellStyle">
				<el-table-column prop="car_no" label="车牌"></el-table-column>
				<el-table-column prop="mobile" label="手机号"></el-table-column>
				<el-table-column prop="type" label="缴费类型"></el-table-column>
				<el-table-column prop="in_time" label="入场时间"></el-table-column>
				<el-table-column prop="pay_time" label="缴费时间"></el-table-column>
				<el-table-column prop="js_time" label="停车时长"></el-table-column>
				<el-table-column prop="order_money" label="缴费金额"></el-table-column>
			</el-table>
			<el-pagination style="width: 100%;text-align: center;" background layout="prev, pager, next" :page-count="last_page"
			 :current-page="page" @current-change="pageChange">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	import {
		// Message,
		MessageBox
	} from 'element-ui';
	import {
		mapState
	} from "vuex";
	import {
		getCashListFn
	} from '../../api/parking.js'
	import tableCss from '../../style/tableCss.js'
	export default {
		props: [],
		data() {
			return {
				car_no: '',
				start_day: '',
				end_day: '',
				cashList: [],
				loading: false,
				page: 1,
				page_size: 10,
				last_page: 0,
			};
		},
		mounted() {
			this.getCashList()
		},
		computed: {
			...mapState(['loadingColor', 'loadingText'])
		},
		methods: {
			searchClick(){
				this.page = 1
				this.getCashList()
			},
			cleaClick(){
				this.car_no = '';
				this.start_day = '';
				this.end_day = '';
			},
			//获取缴费列表
			getCashList() {
				this.loading = true;
				getCashListFn({
					api_token: localStorage.getItem('token1'),
					car_no: this.car_no,
					start_day: this.start_day,
					end_day: this.end_day,
					page: this.page,
					per_page: this.page_size,
				}).then(res => {
					if (res.code == 200) {
						this.cashList = res.datas.data;
						this.last_page = res.meta.last_page;
						this.loading = false;
					}
				})
			},
			//页数改变
			pageChange(page) {
				this.page = page;
				this.getErCode();
			},
			//表头样式
			TableTitleBackColor() {
				return tableCss.tableTitleBackColor();
			},
			//表格样式
			tableCss() {
				return tableCss.tableCss();
			},
			//单元格样式
			cellStyle() {
				return tableCss.cellStyle();
			},
		},
	};
</script>
<style scoped>
	@import url("../../style/detail.css");
	@import url("../../style/title.css");
	.top-box{
		background-color: #F4F4F5;
		margin-bottom: 20px;
	}
</style>
