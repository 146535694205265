import request from '@/api/request.js'

//获取二维码
export function getErCodeFn (data){
  return request({
    baseURL:process.env.VUE_APP_URL_XITONG,
    url:'/api/wxxcx/get_qr_code',
    method:'get',
    params:data
  })
}
//获取图片64位
export function getImgBaseFn (data){
  return request({
    url:'/village/get_img_base64',
    method:'post',
    data:data
  })
}
//获取二维码分类
export function getTypeFn (data){
  return request({
    url:'/village/ztc/get_qrcode_type',
    method:'post',
    data:data
  })
}
//新增二维码
export function addErCodeFn (data){
  return request({
    url:'/village/ztc/add_qrcode',
    method:'post',
    data:data
  })
}
//获取二维码列表
export function getErCodeListFn (data){
  return request({
    url:'/village/ztc/qrcode_list',
    method:'post',
    data:data
  })
}
//删除二维码
export function deleteErCodeFn (data){
  return request({
    url:'/village/ztc/qrcode_delete',
    method:'post',
    data:data
  })
}
//获取缴费列表
export function getCashListFn (data){
  return request({
    url:'/village/ztc/ls_pay_list',
    method:'post',
    data:data
  })
}
//获取停车场编号
export function getParkNumFn (data){
  return request({
    url:'/village/ztc/get_station_info',
    method:'post',
    data:data
  })
}
//获取出口信息
export function getExitFn (data){
  return request({
    url:'/village/ztc/get_eae_info',
    method:'post',
    data:data
  })
}
//保存二维码
export function saveErCodeFn (data){
  return request({
    url:'/village/ztc/save_qrcode',
    method:'post',
    data:data
  })
}